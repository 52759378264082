import IOSchedules from '@/assets/files/consultations-schedules/IO.pdf';
import DSPPSchedules from '@/assets/files/consultations-schedules/DSPP.pdf';
import EUSchedules from '@/assets/files/consultations-schedules/EU.pdf';
import GGSchedules from '@/assets/files/consultations-schedules/GG.pdf';
import GPGPDSchedules from '@/assets/files/consultations-schedules/GPGPD.pdf';
import IOTDSchedules from '@/assets/files/consultations-schedules/IOTD.pdf';
import IVTSchedules from '@/assets/files/consultations-schedules/IVT.pdf';
import MFMMSchedules from '@/assets/files/consultations-schedules/MFMM.pdf';
import PMNOSchedules from '@/assets/files/consultations-schedules/PMNO.pdf';
import POPSchedules from '@/assets/files/consultations-schedules/POP.pdf';
import UPDSchedules from '@/assets/files/consultations-schedules/UPD.pdf';
// import FKSSChedule from '@/assets/files/consultations-schedules/FKS.pdf';
// import LinSchedule from '@/assets/files/consultations-schedules/Lin.pdf';
// import RLSchedule from '@/assets/files/consultations-schedules/RL.pdf';
// import InSchedule from '@/assets/files/consultations-schedules/In.pdf';

export default [
  {
    name: 'Кафедра математики, физики и математического моделирования',
    faculty: 'Факультет информатики, математики и экономики',
    link: MFMMSchedules,
  },
  {
    name: 'Кафедра информатики и вычислительной техники им. Буторина В.К.',
    faculty: 'Факультет информатики, математики и экономики',
    link: IVTSchedules,
  },
  {
    name: 'Кафедра экономики и управления',
    faculty: 'Факультет информатики, математики и экономики',
    link: EUSchedules,
  },
  {
    name: 'Кафедра информатики и общетехнических дисциплин',
    faculty: 'Факультет информатики, математики и экономики',
    link: IOTDSchedules,
  },
  {
    name: 'Кафедра уголовно-правовых дисциплин',
    faculty: 'Факультет истории и права',
    link: UPDSchedules,
  },
  {
    name: 'Кафедра истории и обществознания',
    faculty: 'Факультет истории и права',
    link: IOSchedules,
  },
  {
    name: 'Кафедра государственно-правовых и гражданско-правовых дисциплин',
    faculty: 'Факультет истории и права',
    link: GPGPDSchedules,
  },
  {
    name: 'Кафедра геоэкологии и географии',
    faculty: 'Факультет физической культуры, естествознания и природопользования',
    link: GGSchedules,
  },
  {
    name: 'Кафедра физической культуры и спорта',
    faculty: 'Факультет физической культуры, естествознания и природопользования',
    // link: FKSSChedule,
  },
  {
    name: 'Кафедра естественнонаучных дисциплин',
    faculty: 'Факультет физической культуры, естествознания и природопользования',
  },
  {
    name: 'Кафедра русского языка и литературы',
    faculty: 'Факультет филологии',
    // link: RLSchedule,
  },
  {
    name: 'Кафедра иностранных языков',
    faculty: 'Факультет филологии',
    // link: InSchedule,
  },
  {
    name: 'Кафедра лингвистики',
    faculty: 'Факультет филологии',
    // link: LinSchedule,
  },
  {
    name: 'Кафедра психологии и общей педагогики',
    faculty: 'Факультет психологии и педагогики',
    link: POPSchedules,
  },
  {
    name: 'Кафедра педагогики и методики начального образования',
    faculty: 'Факультет психологии и педагогики',
    link: PMNOSchedules,
  },
  {
    name: 'Кафедра дошкольной и специальной педагогики и психологии',
    faculty: 'Факультет психологии и педагогики',
    link: DSPPSchedules,
  },
];
